<div class="w-100 crumb-c p-2  d-flex justify-content-between ">
    <h4 class="open-sans mb-2 text-nowrap " style="color: #616474;">Sync Service</h4>
    <div class=" d-flex justify-content-between">
        <mat-select (selectionChange)="syncMasterDeatils($event.value)" id="year" class="form-control search-inputbox"
            placeholder="Select Type">
            <!-- <input class="searchIn" mat-input [(ngModel)]="searchType" [ngModelOptions]="{standalone: true}"
                placeholder="Type here to search...." (keydown)="$event.stopPropagation()" (keyup)="searchLabel($event.target.value)"/> -->
            <mat-option value="all">All</mat-option>
            <mat-divider></mat-divider>
            <ng-container *ngFor="let list of syncMasters">
                <mat-option [value]="list">
                    {{list.label[langcode]}}
                </mat-option>
            </ng-container>
        </mat-select>
        <button mat-button 
        (click)="manualSync()" 
        class="syncBtn text-nowrap">
    Start Sync
    </button>
        <img matTooltip="Refresh" [ngClass]="{'refresh_btn': refresh_loader}" (click)="syncMasterDeatils(selectedType)"
            src="./assets/images/svgicons/refresh.svg" alt="" width="30px">
    </div>
</div>

<!-- <div class="mx-3">
    <div class="table-head w-100 d-flex justify-content-between align-items-center">
        <h4>Sync service data </h4>
        <img matTooltip="Refresh" [ngClass]="{'refresh_btn': refresh_loader}" (click)="syncMasterDeatils(selectedType)"
            src="./assets/images/svgicons/refresh.svg" alt="" width="30px">
    </div>
</div> -->
<!-- <div style="padding: 0 16px;">
    <mat-progress-bar *ngIf="refresh_loader || data_loader" mode="indeterminate">
    </mat-progress-bar>
</div> -->
<ng-container *ngIf="dataSource && !dataSource.length && !selectedType">
    <div class="mt-5" fxLayout="row" fxLayoutAlign="center center">
        <span class="material-icons mr-2 text-danger">
            error_outline
        </span>
        <span class="text-danger">Select type to get data for Refresh!</span>
    </div>
</ng-container>
<ng-container *ngIf="dataSource && !dataSource.length && selectedType && !data_loader">
    <div class="mt-5" fxLayout="row" fxLayoutAlign="center center">
        <span class="material-icons mr-2 text-danger">
            error_outline
        </span>
        <span class="text-danger">No records found</span>
    </div>
</ng-container>
<div class="mx-3">
    <div class="table-div w-100">
        
        <ng-container *ngIf="dataSource && dataSource.length">
            
            <table mat-table [dataSource]="dataSource" [class.text-right]="selectedLangCode == 'ar'" matSort
                class="sync-table w-100">
                <ng-container *ngFor=" let cols of displayedColumns">
                    <ng-container matColumnDef="{{ cols }}">
                        <ng-container>
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'sno'">S.No</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'id'">ID</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'label'">Label</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols == 'time_taken'">Time Taken</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='end_date'">End Date</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='existing_count'">Existing
                                    Count</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='success_count'">Success
                                    Count</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='Remarks'">Remarks</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='Status'">Status</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='start_date'">Start Date</span>
                                <span class="open-sans font-weight-bold" *ngIf="cols=='updated_by'">Updated By</span>
                            </th>
                        </ng-container>
                        <!-- <div style="padding: 0 16px;"> 
                            <mat-progress-bar  mode="indeterminate" class="test">
                            </mat-progress-bar>
                        </div> -->
                        <ng-container *ngIf="dataSource">
                            <td mat-cell *matCellDef="let element; let i=index;">
                                <span class="f-14" *ngIf="cols == 'sno'">
                                    {{offset+i+1}}.
                                </span>
                                <div *ngIf="cols == 'Action'">
                                    <button class="reject-button icon-btn" mat-button (click)="stopProcess(element)"
                                        mat-tooltip="Stop Process" [disabled]="element.status!=1">
                                        <span> Stop Process</span>
                                    </button>
                                </div>
                                <div *ngIf="cols=='Status'">
                                    <span
                                        [class.red]="getStatus(element.status)=='Invalid' || getStatus(element.status)=='Process Stopped'"
                                        [class.green]="getStatus(element.status)=='Completed'">{{getStatus(element.status)}}</span>
                                </div>
                                <ng-container *ngIf="cols == 'Remarks'">
                                    <div matTooltip={{returnVal(element,cols)}}
                                        (contextmenu)="open($event, element); $event.preventDefault();"
                                        class="text-capitalize more" style="cursor: default;">
                                        {{returnVal(element, cols)}}
                                    </div>
                                    <ng-template #copyMenu let-element>
                                        <section class="copy-menu">
                                            <button mat-button (click)="copy(element)">Copy {{cols}}</button>
                                        </section>
                                    </ng-template>
                                </ng-container>

                                <p matTooltip={{returnVal(element,cols)}} *ngIf="cols == 'Description' || cols == 'URL'"
                                    class="more f-14 open-sans">
                                    {{returnVal(element, cols)}}</p>
                                <p matTooltip={{returnVal(element,cols)}}
                                    *ngIf="cols != 'S.No' && cols !='Action' && cols!='Status' && cols != 'Remarks'"
                                    class="f-14 open-sans">
                                    {{returnVal(element, cols)}}</p>

                            </td>
                        </ng-container>

                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
            </table>
        </ng-container>

    </div>
</div>
<ng-container *ngIf="dataSource">
    <div class="d-flex mt-2 justify-content-end">
        <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event; handlePagination($event)">
        </mat-paginator>
    </div>
</ng-container>